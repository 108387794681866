import { Button, Toast } from '@getvim/atomic-ui';
import { isEmpty, keyBy } from 'lodash-es';
import React, { useCallback, useEffect, useState } from 'react';
import { IconClose } from '../../assets/icons';
import Loader from '../../components/loader';
import { Application } from '../../types';
import AccessToken from './AccessToken';
import ApplicationManagement from './application-management/ApplicationManagement';
import {
  getSDKLocalStorage,
  mergeSDKLocalStorageKey,
  updateSDKLocalStorage,
} from './sdkLocalStorage';
import './style.less';
import { AppToOverride, AppWithOverride } from './types';
import useApi from '../../hooks/useApi';

const { ToastTypes, createToast } = Toast;

type Props = {
  onClose: () => void;
  isLoadingApps: boolean;
  organizationApps: Application[];
};

const localStorageKey = 'appOverrides';

export const updateAppOverride = ({ appId, ...override }: AppToOverride) => {
  mergeSDKLocalStorageKey(localStorageKey, { [appId]: override });
};

const VimSettings: React.FC<Props> = ({ onClose, isLoadingApps, organizationApps }: Props) => {
  const { updateUserEnabledApps } = useApi();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [savedApps, setSavedApps] = useState<Record<string, AppWithOverride>>({});
  const [updatedApps, setUpdatedApps] = useState<Record<string, AppWithOverride>>({});
  useEffect(() => {
    if (isLoadingApps) {
      return;
    }
    const overrides = getSDKLocalStorage()[localStorageKey] ?? {};
    const appsWithOverrides: Record<string, AppWithOverride> = organizationApps.reduce(
      (acc, { id, ...app }) => ({
        ...acc,
        [id]: {
          appId: id,
          ...app,
          ...overrides[id],
        },
      }),
      {} as Record<string, AppWithOverride>,
    );
    setSavedApps(appsWithOverrides);
    setUpdatedApps(appsWithOverrides);
  }, [isLoadingApps, organizationApps]);

  const cancel = useCallback(() => {
    setUpdatedApps(keyBy(savedApps, 'appId'));
  }, [savedApps]);
  const save = useCallback(async () => {
    const hasEnabledAppsChange = Object.values(updatedApps).some(
      (app) => app.isEnabledForUser !== savedApps[app.appId]?.isEnabledForUser,
    );
    if (hasEnabledAppsChange) {
      try {
        setIsSaving(true);
        await updateUserEnabledApps(
          Object.keys(updatedApps).filter((appId) => updatedApps[appId].isEnabledForUser),
        );
      } catch (error) {
        console.error('Failed to update enabled apps', error);
        createToast({
          title: `Oops, error!`,
          message: 'Failed to update enabled apps',
          type: ToastTypes.ERROR,
          position: 'bottom-right',
        });
        return;
      } finally {
        setIsSaving(false);
      }
    }
    updateSDKLocalStorage(
      localStorageKey,
      Object.entries(updatedApps).reduce((acc, [appId, app]) => {
        if (!app.version && !app.url && !app.launchEndpoint && !app.tokenEndpoint) {
          return acc;
        }
        return {
          ...acc,
          [appId]: {
            version: app.version,
            url: app.url,
            launchEndpoint: app.launchEndpoint,
            tokenEndpoint: app.tokenEndpoint,
          },
        };
      }, {}),
    );
    setSavedApps(updatedApps);
    window.location.reload();
  }, [updatedApps, savedApps, updateUserEnabledApps]);

  return (
    <div className="vim-settings">
      <div className="top-bar">
        <div className="title">Vim settings</div>
        <Button buttonType="link" onClick={onClose}>
          <IconClose />
        </Button>
      </div>
      <div className="content">
        <AccessToken />
        <hr />
        {isLoadingApps ? (
          <Loader />
        ) : (
          <ApplicationManagement applications={updatedApps} setApplications={setUpdatedApps} />
        )}
      </div>
      {!isEmpty(savedApps) && (
        <div className="commit-buttons align-right">
          <Button buttonType="small" bgColor="white" onClick={cancel} disabled={isSaving}>
            Cancel
          </Button>
          <Button
            buttonType="small"
            onClick={save}
            disabled={isSaving}
            data-testid="save-settings-button"
          >
            Save and reload page
          </Button>
        </div>
      )}
    </div>
  );
};

export default VimSettings;
